import { useContext } from 'react';
import AuthUserContext from '../components/AuthUserContext/AuthUserContext';

export function useAuthenticatedUser() {
  const authenticatedUser = useContext(AuthUserContext);
  return authenticatedUser;
}

export function useAuthorizedUser(authCondition) {
  const authenticatedUser = useContext(AuthUserContext);
  const isAuthorized = authenticatedUser ? authCondition(authenticatedUser) : false;
  return isAuthorized ? authenticatedUser : null;
}
