import React from 'react';
import { DialogContainer } from 'react-md';

const LoginErrorDialogMessage = ({ dialogIsVisible, onHide }) => {
  const dialogActions = [
    {
      onClick: onHide,
      primary: true,
      children: 'I understand'
    }
  ];

  return (
    <DialogContainer
      actions={dialogActions}
      aria-describedby='login-error-dialog-description'
      id='login-error-dialog'
      focusOnMount={false}
      onHide={onHide}
      modal
      title='Uh oh. An Error!'
      titleStyle={{ color: 'red' }}
      visible={dialogIsVisible}
    >
      <p id='login-error-dialog-description' className='md-color--secondary-text'>
        Although we were able to sucessfully log you in, we saw an error along the way.
      </p>
      <br />
      <p>
        <strong>Please reload the page or continue to use the site with limited abilities.</strong>
      </p>
    </DialogContainer>
  );
};

export default LoginErrorDialogMessage;
