import { db } from '../firebase';

let mentalModelsRef = db.ref('/mental-models');
export const onGetMentalModels = cb => mentalModelsRef.on('value', cb);
export const offGetMentalModels = cb => {
  mentalModelsRef.off('value', cb);
};

let authoredMentalModelsRef = db.ref('/mental-models');
export const onGetAuthoredMentalModels = (userId, cb) => {
  authoredMentalModelsRef
    .orderByChild(`createdByUserId`)
    .equalTo(userId)
    .on('value', cb);
};
export const offGetAuthoredMentalModels = cb => {
  authoredMentalModelsRef.off('value', cb);
};

let favoriteMentalModelsRef = db.ref('/mental-models');
export const onGetFavoriteMentalModels = (userId, cb) => {
  favoriteMentalModelsRef
    .orderByChild(`favoritedBy/${userId}`)
    .equalTo(true)
    .on('value', cb);
};
export const offGetFavoriteMentalModels = cb => {
  favoriteMentalModelsRef.off('value', cb);
};

let mentalModelRef;
export const onGetMentalModel = (mentalModelId, cb) => {
  mentalModelRef = db.ref(`/mental-models/${mentalModelId}`);
  mentalModelRef.on('value', cb);
};
export const offGetMentalModel = cb => {
  if (mentalModelRef) {
    mentalModelRef.off('value', cb);
  }
};

export const doDeleteMentalModel = mentalModelId => {
  db.ref(`/mental-models/${mentalModelId}`).remove();
};

export const doUpdateMentalModel = (mentalModelId, updatedMentalModel, callback) => {
  db.ref(`/mental-models/${mentalModelId}`).set(updatedMentalModel, error => {
    // TODO log error in a service or something
    callback(error, mentalModelId);
  });
};

export const doCreateMentalModel = (newMentalModel, callback) => {
  const mmRefs = db.ref().child('/mentalModels');
  const mmRef = mmRefs.push(newMentalModel, error => {
    // TODO log error in a service or something
    if (error) callback(error);
  });
  const mentalModelId = mmRef.key;

  if (mentalModelId) {
    db.ref(`/mental-models/${mentalModelId}`).set(
      { ...newMentalModel, id: mentalModelId },
      error => {
        // TODO log error in a service or something
        callback(error, mentalModelId);
      }
    );
  }
};
