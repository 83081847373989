import { storage } from './firebase';

import { STORAGE_PATHS } from '../constants/enums';

// top images (mental models)
export const getMentalModelImageUrl = async (imageKey, cb) => {
  // TODO - add error handling here: https://firebase.google.com/docs/storage/web/download-files
  const url = await storage.ref(imageKey).getDownloadURL();
  cb(url);
};

export const uploadMentalModelImage = async (imageKey, file) => {
  const rootStorageRef = storage.ref();
  const mentalModelRef = rootStorageRef.child(`${STORAGE_PATHS.MENTAL_MODELS}${imageKey}`);
  const snapshot = await mentalModelRef.put(file);
  return snapshot;

  // TODO error handling / toasts or success message / progress
};

export const deleteMentalModelImage = async imageKey => {
  // Create a reference to the file to delete\
  const rootStorageRef = storage.ref();
  const mentalModelRef = rootStorageRef.child(`${STORAGE_PATHS.MENTAL_MODELS}${imageKey}`);

  // Delete the file
  mentalModelRef
    .delete()
    .then(function() {
      // File deleted successfully
      return 'Successfully Deleted File';
    })
    .catch(function(error) {
      // Uh-oh, an error occurred!
      return error;
    });
};
