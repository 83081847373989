import { db } from '../firebase';

// ---------------User API--------------------
/**
 * Create a new user
 * @param {Object} userObject
 */
export const doCreateUser = (newUser, callback) => {
  return db.ref(`users/${newUser.id}`).set(newUser, error => callback(error));
};

export const onceGetUserById = async userUid => {
  try {
    const getUserRef = db.ref(`/users/${userUid}`);
    const snapshot = await getUserRef.once('value');
    return snapshot.val();
  } catch (error) {
    // TODO throw sometime of log in the service
    return null;
  }
};

let getUserFavoritesRef;
/**
 * Get a users favorite object from firebase based on id, set listener call bacl
 * for changes
 * @param {String} userId
 * @param {Function} cb
 */
export const onGetUserFavorites = (userId, cb) => {
  getUserFavoritesRef = db.ref(`users/${userId}/favorites`);
  getUserFavoritesRef.on('value', cb);
};

/**
 * Turn off user favorite listener
 * @param {Function} cb
 */
export const offGetUserFavorites = cb => {
  if (getUserFavoritesRef) {
    getUserFavoritesRef.off('value', cb);
  }
};

/**
 * Get a users favorite object from firebase based on id, set listener call back
 * for changes
 * @param {String} userId
 * @param {Function} cb
 */
export const setUserFavorite = async (userId, mmId) => {
  try {
    const favoritesRef = db.ref(`users/${userId}/favorites`);
    const favoritesSnapshot = await favoritesRef.once('value');
    let userFavorites = favoritesSnapshot.val();

    // first update the user to include mental model as a favorite
    if (userFavorites) {
      userFavorites[mmId] = !userFavorites[mmId];
    } else {
      userFavorites = {};
      userFavorites[mmId] = true;
    }
    favoritesRef.set(userFavorites);

    // add this user id to the favoritedBy mental model field
    const mmsRefs = db.ref(`mental-models/${mmId}/favoritedBy`);
    const mmsSnapshot = await mmsRefs.once('value');
    let mmFavorites = mmsSnapshot.val();

    if (mmFavorites) {
      mmFavorites[userId] = userFavorites[mmId] || null;
    } else {
      mmFavorites = {};
      mmFavorites[userId] = true;
    }
    mmsRefs.set(mmFavorites);

    return true;
  } catch (error) {
    // TODO log error on a service or in firebase
    return false;
  }
};
