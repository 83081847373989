import React from 'react';
import { auth } from '../../../firebase';
import { Button } from 'react-md';

/**
 * Button wrapper that signs out from firebase on click
 *
 * @returns {Component}
 */
const SignOutButton = () => (
  <Button flat secondary onClick={auth.doSignOut}>
    Sign Out
  </Button>
);

export default SignOutButton;
