import React from 'react';
import ErrorUserMessage from '../UserMessages/ErrorUserMessage';

class NavigationErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // TODO - log this to firebase? log to service?
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorUserMessage />;
    }

    return this.props.children;
  }
}

export default NavigationErrorBoundary;
