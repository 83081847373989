// absolute
import { NavigationDrawer } from 'react-md';
import React, { lazy, Suspense, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Route, Switch } from 'react-router-dom';
import withTracker from '../../hoc/withTracker';

// relatives
import { ADMIN_ROLE } from '../../constants/user';
import { PAGE_TITLES, ROUTES } from '../../constants/enums';
import { ADMIN_NAV_ITEMS, AUTH_NAV_ITEMS, PUBLIC_NAV_ITEMS } from '../../constants/navItems';
import LoginErrorDialogMessage from '../UserMessages/LoginErrorDialogMessage';
import NavLink from '../NavLink/NavLink';
import NavigationErrorBoundary from '../../components/ErrorBoundaries/NavigationErrorBoundary';
import PageLoadingSpinner from '../PageLoadingSpinner/PageLoadingSpinner';
import SignInButton from '../../components/Buttons/SignInButton/SignInButton';
import SignUpButton from '../../components/Buttons/SignUpButton/SignUpButton';
import SignOutButton from '../../components/Buttons/SignOutButton/SignOutButton';
import { useAuthenticatedUser } from '../../hooks/firebaseHooks';

import withAuthentication from '../../hoc/withAuthentication';

// styles
import './Navigation.css';

// Dynamically import routes, utilizing react lazy 🚀
const AboutMentalModelsPage = lazy(() => import('../../pages/AboutMentalModels/AboutMentalModels'));
const AboutProjectPage = lazy(() => import('../../pages/AboutProject/AboutProject'));
const AdminDictionaryPage = lazy(() => import('../../pages/AdminDictionary/AdminDictionary'));
const AdminMentalModelEditorPage = lazy(() =>
  import('../../pages/AdminMentalModelEditor/AdminMentalModelEditor')
);
const FeedbackPage = lazy(() => import('../../pages/Feedback/Feedback'));
const FourOhFourPage = lazy(() => import('../../pages/FourOhFour/FourOhFour'));
const UserDictionaryPage = lazy(() => import('../../pages/UserDictionary/UserDictionary'));
const UserMentalModelPage = lazy(() => import('../../pages/UserMentalModel/UserMentalModel'));
const UserMentalModelEditorPage = lazy(() =>
  import('../../pages/UserMentalModelEditor/UserMentalModelEditor')
);
const SignInPage = lazy(() => import('../../pages/SignIn/SignIn'));
const SignUpPage = lazy(() => import('../../pages/SignUp/SignUp'));

/**
 * Based on the location the user is viewing, get the correct title
 * for our nav toolbar
 * @param {String} locationPath
 * @returns {String}
 */
const _getToolbarTitleFromLocation = locationPath => {
  if (locationPath === ROUTES.INDEX_PATH) {
    return PAGE_TITLES.INDEX;
  } else if (locationPath === ROUTES.ABOUT_MM_PATH) {
    return PAGE_TITLES.ABOUT_MMS;
  } else if (locationPath === ROUTES.ABOUT_PROJECT_PATH) {
    return PAGE_TITLES.ABOUT_PROJECT;
  } else if (locationPath === ROUTES.USER_ADD_MM_PATH) {
    return PAGE_TITLES.ADD_MM;
  } else if (locationPath.includes(ROUTES.USER_EDIT_MM_PATH)) {
    return PAGE_TITLES.EDIT_MM;
  } else if (locationPath.includes(ROUTES.VIEW_MENTAL_MODEL_PATH)) {
    return PAGE_TITLES.VIEW_MM;
  } else {
    return PAGE_TITLES.DEFAULT;
  }
};

/**
 * Gets the correct nav items array based on the auth user credentials
 * 1. If no auth, return public nav items
 * 2. if auth but not admin, return auth nav items
 * 3. if auth and admin, return admin nav items
 * @param {Object} authUser - auth user from firebase
 * @returns {Array}
 */
const _getNavItems = authUser => {
  if (!authUser) return PUBLIC_NAV_ITEMS;

  const {
    mmdUserInfo: { role }
  } = authUser;
  return role === ADMIN_ROLE ? ADMIN_NAV_ITEMS : AUTH_NAV_ITEMS;
};

/**
 * Based on the auth user credentials, set up our tool bar action items correctly
 * 1. if there is an auth user, show sign out button
 * 2. if there is not, show sign up / sign in buttons
 * @param {Object} authUser - auth user from firebase
 *
 * @return {Component}
 */
const _getToolbarAction = authUser => {
  if (authUser) {
    return <SignOutButton />;
  }

  const actions = [];
  actions.push(<SignUpButton />);
  actions.push(<SignInButton />);
  return actions;
};

/**
 * Pure component that handles routing via react router, sets up the navigation
 * drawer from react-md library, and sets nav items, toolbar actions based on
 * the user access
 */
const Navigation = ({ location }) => {
  const authUser = useAuthenticatedUser();
  const [dialogIsVisible, setDialogIsVisible] = useState(true);

  return (
    <NavigationDrawer
      className='Navigation'
      drawerTitle='Menu'
      navItems={_getNavItems(authUser).map(props => (
        <NavLink {...props} key={props.to} />
      ))}
      toolbarActions={_getToolbarAction(authUser)}
      toolbarTitle={_getToolbarTitleFromLocation(location.pathname)}
    >
      {authUser && authUser.mmdUserInfo.error && (
        <LoginErrorDialogMessage
          dialogIsVisible={dialogIsVisible}
          onHide={() => setDialogIsVisible(false)}
        />
      )}
      <NavigationErrorBoundary>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames='fade' timeout={750}>
            <Suspense fallback={<PageLoadingSpinner scale={3} />}>
              <Switch location={location} key={location.key}>
                {/* Public Pages */}
                <Route
                  component={withTracker(AboutMentalModelsPage)}
                  exact
                  location={location}
                  path={ROUTES.ABOUT_MM_PATH}
                />
                <Route
                  component={withTracker(AboutProjectPage)}
                  exact
                  location={location}
                  path={ROUTES.ABOUT_PROJECT_PATH}
                />
                <Route
                  component={withTracker(SignInPage)}
                  exact
                  location={location}
                  path={ROUTES.SIGN_IN_PATH}
                />
                <Route
                  component={withTracker(SignUpPage)}
                  exact
                  location={location}
                  path={ROUTES.SIGN_UP_PATH}
                />

                {/* USER PAGES */}
                <Route
                  component={withTracker(UserDictionaryPage)}
                  exact
                  location={location}
                  path={ROUTES.INDEX_PATH}
                />
                <Route
                  component={withTracker(UserMentalModelPage)}
                  location={location}
                  path={`${ROUTES.VIEW_MENTAL_MODEL_PATH}:mentalModalId`}
                />
                <Route
                  component={withTracker(UserMentalModelEditorPage)}
                  location={location}
                  path={ROUTES.USER_ADD_MM_PATH}
                />
                <Route
                  component={withTracker(UserMentalModelEditorPage)}
                  location={location}
                  path={`${ROUTES.USER_EDIT_MM_PATH}:mentalModalId`}
                />
                <Route
                  component={withTracker(FeedbackPage)}
                  location={location}
                  path={ROUTES.FEEDBACK}
                />

                {/* ADMIN PAGES */}
                <Route
                  component={withTracker(AdminMentalModelEditorPage)}
                  location={location}
                  path={`${ROUTES.ADMIN_EDIT_MM_PATH}:mentalModalId`}
                />
                <Route
                  component={withTracker(AdminMentalModelEditorPage)}
                  location={location}
                  path={ROUTES.ADMIN_ADD_MM_PATH}
                />
                <Route
                  component={withTracker(AdminDictionaryPage)}
                  exact
                  location={location}
                  path={ROUTES.ADMIN_PATH}
                />

                {/* 404 */}
                <Route component={withTracker(FourOhFourPage)} />
              </Switch>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </NavigationErrorBoundary>
    </NavigationDrawer>
  );
};

export default withAuthentication(Navigation);
