import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';
import './App.css';

class App extends Component {
  render() {
    return <Route render={({ location }) => <Navigation location={location} />} />;
  }
}

export default App;
