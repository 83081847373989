import React from 'react';
import { CircularProgress } from 'react-md';

import './PageLoadingSpinner.css';

const PageLoadingSpinner = ({ message = 'Loading Page...', scale = 3 }) => {
  return (
    <section className='PageLoadingSpinner md-grid'>
      <div className='md-cell--12 md-cell--center'>
        <CircularProgress id='pageLoadingSpinner' scale={scale} />
      </div>
      <div className='md-cell--12 md-cell--center'>
        <div className='loading-message'>{message}</div>
      </div>
    </section>
  );
};

export default PageLoadingSpinner;
