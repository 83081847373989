import React from 'react';
import { Cell, Grid, Paper, Subheader } from 'react-md';

import './UserMessage.css';

const UserMessage = ({ html, desktopOffset = 0, size = 12, tabletOffset = 0, title }) => {
  return (
    <Grid className='UserMessage'>
      <Cell desktopOffset={desktopOffset} tabletOffset={tabletOffset} size={size}>
        <Paper zDepth={2} className='paper-padding--large'>
          <Subheader
            className='md-divider-border md-divider-border--bottom'
            component={'h2'}
            primary
            primaryText={title}
            style={{ textAlign: 'center', marginBottom: 0 }}
          />
          <div
            className=' subtitle--margin--top'
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />
        </Paper>
      </Cell>
    </Grid>
  );
};

export default UserMessage;
