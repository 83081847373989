import React from 'react';
import UserMessage from './UserMessage';

const ErrorUserMessage = () => (
  <UserMessage
    html={`<p style="text-align: center">We sincerely apologize, and hope to take care of this for you.<br ><br >Our first suggestion, refresh and retry or <a href = "mailto: kalebmckelvey3@gmail.com">e-mail me for assistance!</a></p>`}
    desktopOffset={3}
    size={6}
    tabletOffset={1}
    title='Oh no, an error occured! ☹️'
  />
);

export default ErrorUserMessage;
