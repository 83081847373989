export const EDITOR_TYPES = {
  DEFINITION: 'definition',
  DEFENSE: 'defense',
  EXAMPLE: 'example',
  RESOURCES: 'resources',
  SUMMARY: 'summary'
};

export const ENVIRONMENTS = {
  DEV: 'development',
  PROD: 'production'
};

export const FILE_SEPARATORS = {
  PERIOD: '.'
};

export const PAGE_TITLES = {
  ADD_MM: 'Add New MM (BETA)',
  ABOUT_MMS: 'About Mental Models',
  ABOUT_PROJECT: 'About the Project',
  DEFAULT: 'Mental Model Dictionary',
  EDIT_MM: 'Edit Your MM (BETA)',
  INDEX: 'Mental Model Dictionary',
  VIEW_MM: 'Mental Model Entry'
};

export const RECAPTCHA_KEYS = {
  ALL: '6Ldq1nQUAAAAAKr5YiIoSr5NF148jnXhDZTH-NSt'
};

export const ROUTES = {
  INDEX_PATH: '/',
  ADMIN_PATH: '/admin',
  ADMIN_ADD_MM_PATH: '/admin/mental-model/add',
  ADMIN_EDIT_MM_PATH: '/admin/mental-model/edit/',
  ABOUT_MM_PATH: '/about-mental-models',
  ABOUT_PROJECT_PATH: '/about-the-project',
  FEEDBACK: '/feedback',
  RESET_PASS_PATH: '/reset-pw',
  SIGN_IN_PATH: '/sign-in',
  SIGN_UP_PATH: '/sign-up',
  USER_ADD_MM_PATH: '/user/mental-model/add',
  USER_EDIT_MM_PATH: '/user/mental-model/edit/',
  VIEW_MENTAL_MODEL_PATH: '/mental-model/'
};

export const STORAGE_PATHS = {
  MENTAL_MODELS: 'mental-models/'
};

export const TAB_LABELS = {
  DEFINITION: 'Definition',
  EXAMPLE: 'Example',
  DEFENSE: 'Defense',
  RESOURCES: 'Resources'
};

export const SECONDS = {
  ONE: 1000,
  ONE_AND_HALF: 1500
};
